/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";

import Header from "./header"
import "../assets/css/layout.css"

import background from "../assets/images/background_pattern.png"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div style={{
      backgroundImage: 'url('+background+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right top',
      backgroundSize: '55% auto'
    }}>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1920,
        }}
      >
        <main>{children}</main>

        <footer className="footer">
          <StaticImage
            src="../assets/images/logo.png"
            quality={100}
            formats={["auto", "webp", "avif"]}
            className="footer_logo"
            alt="Hero"
          />
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
